var fixedMenuActive = false;

function showfixedMenu() {
    var ww = $(window).width();
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1600px,
    if (ww < 992) {
        hideFixedMenu(function () {
            if ($('#top-search.dropdown-open').lenght > 0) {
                $('#search-input-sticky').autocomplete('close');
            }
        });
        return;
    }
    var scrollHeight = $('#top-row').outerHeight() + $('#logo-row').outerHeight();
    if ($(window).scrollTop() > scrollHeight) {
        if (!fixedMenuActive) {
            fixedMenuActive = true;
            $("#menu-and-slider, #main-menu-row").addClass('scrolled');
        }
    } else {
        if (fixedMenuActive) {
            hideFixedMenu(function () {
                if ($('#top-search.dropdown-open').lenght > 0) {
                    $('#search-input-sticky').autocomplete('close');
                }
            });
        }
    }
}

function hideFixedMenu(callback) {
    fixedMenuActive = false;
    // $("#main-menu-row .animated").removeClass('animated');
    $("#menu-and-slider, #main-menu-row").removeClass('scrolled');
    $('#scrolledSearch').collapse('hide');

    if (callback !== undefined) {
        callback.call();
    }
}

$('#scrolledSearch').on('show.bs.collapse', function () {
    $('#scrolledSearch').css('background','#fff');
})

$('#scrolledSearch').on('shown.bs.collapse', function () {
    $('#search-input-sticky').focus();
})

$(window).scroll(function () {
    showfixedMenu();
});

$(window).bind("load resize", function () {
    showfixedMenu();
});
