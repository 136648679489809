$(window).bind("load resize", function () {
    handleMainMenu();
});

function handleMainMenu() {
    var ww = $(window).width();
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1600px,
    if (ww < 992) {
        $('#main-menu > li a.dropdown-toggle').addClass('removed-dropdown-toggle').removeClass('dropdown-toggle').removeAttr('data-toggle')
        $('#main-menu > li.dropdown').addClass('removed-dropdown').removeClass('dropdown');
    }
    else {
        $('#main-menu > li.removed-dropdown').addClass('dropdown').removeClass('removed-dropdown');
        $('#main-menu > li a.removed-dropdown-toggle').addClass('dropdown-toggle').removeClass('removed-dropdown-toggle').attr('data-toggle', 'dropdown');
    }
}

$('header #menu-and-slider ul.navbar-nav li.nav-item.dropdown').on('mouseenter', function (e) {
    $(e.target).closest('.dropdown').addClass('show');
    $(e.target).closest('.dropdown').find('.dropdown-menu').addClass('show');
});
$('header #menu-and-slider ul.navbar-nav li.nav-item.dropdown').on('mouseleave', function (e) {
    $(e.target).closest('.dropdown').removeClass('show');
    $(e.target).closest('.dropdown').find('.dropdown-menu').removeClass('show');
});
