/*$(window).on('load', function () {
    $('#preloader').fadeOut('slow', function () {
        //$(this).remove();
        $('body').addClass('loaded');
    });
});*/
$('#preloader').hide();
$('#preloader').css('z-index', '9999');

function preloaderShow() {
    $('#preloader').show();
    $('body').removeClass('loaded');
}