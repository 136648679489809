/* Autocomplete coded by gygy*/

var autocomplete_registered = false;

$(function() {
    var ac_ajax             = false,
        ac_cache_lifetime   = 30;

    // Check cache content
    if (typeof(Storage) !== "undefined") {
        var ac_data = localStorage.getItem('ac_data_tytoo');

        // Autocomplete data is expired
        if ( ! ac_data || ac_data === NaN || Date.parse(ac_data.expire) < new Date()) {
            ac_ajax = true;
        }
    }

    if (ac_ajax) {
        $.ajax({
            type        :   'get',
            url         :   '/product/products?format=json',
            dataType    :   'json',

            success     :   function(data) {
                initAutocomplete({
                    element             :   '#search-input',
                    data                :   data,
                    containment         :   '#top-search'
                });

                // Client side caching
                var exp = new Date();
                exp.setMinutes(exp.getMinutes() + ac_cache_lifetime);

                localStorage.setItem('ac_data_tytoo', JSON.stringify({
                    expire  :   exp,
                    data    :   data
                }));

                // Disable other request
                ac_ajax = false;
            }
        });
    } else {
        ac_data = $.parseJSON(localStorage.getItem('ac_data_tytoo'));

        // Get it from cache
        if ((typeof ac_data === undefined || typeof(ac_data)) !== 'object') {
            e.preventDefault();
        }

        initAutocomplete({
            element             :   '#search-input',
            data                :   ac_data.data,
            containment         :   '#top-search'
        });
    }
});

function initAutocomplete(options) {
    if (autocomplete_registered) {
        return false;
    }

    var el = options.element instanceof jQuery ? options.element : $(options.element);

    if (el.length > 0) {
        el.autocomplete({
            minLength: 3,
            source: function(request, response) {
                var array = $.map(options.data, function (item) {
                    return {
                        label: item.title,
                        thumb: item.thumb,
                        value: item.href
                    }
                });

                // Call the filter here
                response($.ui.autocomplete.filter(array, request.term).slice(0, 5));
            },
            appendTo    : options.containment,
            open        : function(event, ui) {
                $(options.containment).addClass('dropdown-open');
                $('ul.ui-autocomplete').prepend(
                    '<li class="header _purple-bg px-2">' +
                        '<div class="pull-left text-white"><small><b>Találatok</b></small></div>' +
                        '<div class="pull-right text-white">' +
                            '<small>' +
                                '<a onclick="window.location=\'/kereses?search=' + encodeURI($('#search-input').val()) + '\';" href="javascript:void(0);" class="text-white">Összes találat <i class="fa fa-angle-double-right" aria-hidden="true"></i></a>' +
                            '</small>' +
                        '</div>' +
                        '<div class="clearfix"></div>' +
                    '</li>'
                ).css('padding', '0');
            },
            close       : function() {
                $(options.containment).removeClass('dropdown-open');
            },
            select: function select(event, ui) {
                var href = '';
                if (ui.item && ui.item.value){
                    href = ui.item.value;
                    ui.item.value = '';
                }

                if (href.length > 0) {
                    window.location = href;
                }
                //console.log('select');
            },
            focus: function focus(event, ui) {
                return false;
            },
            delay       : 300
        }).data("ui-autocomplete")._renderItem = function( ul, item ) {
            // Template
            var item =  '<div class="float-left image-col pl-2 py-2">' +
                            '<a href="' + item.value + '">' +
                                '<img src="' + item.thumb + '"    alt="">' +
                            '</a>' +
                        '</div>' +
                        '<div class="float-left label-col px-2 py-2">' +
                            '<a href="' + item.value + '" class="_purple">' +
                                '<small>' + item.label + '</small>' +
                            '</a>' +
                        '</div>' +
                        '<div class="clearfix"></div>';

            return $('<li>').append(item).appendTo(ul);
        };

        autocomplete_registered = true;
    }
};