function scrollToId(id, delay, speed, offset) {
    if (delay === undefined) {
        var delay = 100;
    }
    if (speed === undefined) {
        var speed = 1000;
    }
    if (offset === undefined) {
        var offset = -30;
    }
    try {
        $("html, body").delay(delay).animate({scrollTop: $("#" + id).offset().top + offset}, speed)
    } catch (err) {
    }
};