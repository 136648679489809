$(".liame:not(a)").each(function () {
    var em = $(this).data("liame-0") + "@" + $(this).data("liame-1") + "." + $(this).data("liame-2");
    if ($(this).data("liame-to")==true) {
        em =  '<a href="mailto:' + em + '">' + em + '</a>';
    }
    $(this).html(em);
});
$("a.liame").each(function () {
    var em = $(this).data("liame-0") + "@" + $(this).data("liame-1") + "." + $(this).data("liame-2");
    $(this).attr('href', 'mailto:' + em);
    $(this).html(em);
});
