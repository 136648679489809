var gotTopActive = false;

function showGoTop() {

    if ($(window).scrollTop() > 200) {
        if (!gotTopActive) {
            gotTopActive = true;
            $("#go-top").fadeIn(400, 'linear')
        }
    } else {
        if (gotTopActive) {
            gotTopActive = false;
            $("#go-top").fadeOut(400, 'linear')
        }
    }
}

$(window).bind("load", function () {
    showGoTop();
});

$(window).scroll(function () {
    showGoTop();
});

$(function () {
    $('body').append('<div id="go-top"></div>');
    showGoTop();
    $("#go-top").click(function (a) {
        a.preventDefault();
        $("html, body").animate({scrollTop: 0}, 300);
    });
});