var $animation_elements = $('.scroll-animate');
var $window = $(window);
$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

$window.on('load', function () {
    $.each($animation_elements, function () {
        resetAnimate($(this));
    });
});

function check_if_in_view() {

    var ww = $(window).width();
// xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1600px,
    if (ww < 768) {
        removeAnimate($animation_elements);
        return;
    }

    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var offset = 30;
        var element_top_position = $element.offset().top + offset;
        var element_bottom_position = (element_top_position + element_height) - offset;

        var fromAbove = (element_top_position <= window_bottom_position);
        var fromBelow = (element_bottom_position >= window_top_position);

        if (fromAbove && fromBelow && !$element.hasClass('remove-animated')) {
            $element.addClass('animated');
        }
        else {
            removeAnimate($element);
        }
    });
}

function removeAnimate($element) {
    if (!$element.hasClass('repeat-animate') && $element.hasClass('animated')) {
        $element.addClass('remove-animated');
    }

    /*  if (!$element.hasClass('repeat-animate') && $element.hasClass('animated') && !$element.hasClass('remove-animated-first')) {
          $element.addClass('remove-animated-first');
      }
      else if(!$element.hasClass('repeat-animate') && $element.hasClass('animated') && $element.hasClass('remove-animated-first')) {
          $element.removeClass('remove-animated-first');
          $element.addClass('remove-animated');
      }*/
    $element.removeClass('scroll-animate');
    $element.removeClass('animated');
    resetAnimate($element);
}

function resetAnimate($element) {
    $element.css("animation", 'none');
    $element.outerHeight();
    $element.css("animation", '');
}
