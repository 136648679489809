var GLSPSMap = function () { };

GLSPSMap.prototype = function () {
    var allowedLangCtrCodes = ['CZ', 'HU', 'EN', 'RO', 'SI', 'SK', 'HR'];
    var ajaxUrl = '//online.gls-hungary.com/psmap/psmap_getdata.php';

    var psData = [];
    var lngData = [];
    var psData_OnMap = [];
    var selectedID = '';
    var _ctrcode = '';
    var _senderid = null;
    var _pclshopin = null;
    var _parcellockin = null;
    var _isDropOff = null;
    var _initAddress = null;
    var _codhandler = null;
    var _baselatlng = {lat : 0, lng : 0};

    var init = function (ctrcode, containerID, address, isDropOff, senderid, pclshopin, parcellockin, codhandler) {
        // set variables to init values
        destroy();

        // container for the map
        container = $('#' + containerID);
        self = this;
        container.bind('change.unbindable', function(e) {
            if (self._onSelect !== undefined) {
                for (var i = 0; i < psData.length; i++) {
                    var id = $(this).val();
                    if (psData[i]['pclshopid'] === id) {
                        if (psData[i].openings) {
                            self._onSelect.call(false, psData[i]);
                        }
                        else {
                            crossDomainAjax(
                                {
                                    action: 'getOpenings',
                                    pclshopid: id
                                },
                                $.proxy(function (data) {
                                    psData[i].openings = data;
                                    self._onSelect.call(false, psData[i]);
                                }, this));
                        }
                        break;
                    }
                }
            }

            e.stopImmediatePropagation();
        });

        // prepare start address
        if (isEmpty(address)) {
            switch (ctrcode.toUpperCase()) {
                case 'HU':
                    address = 'Budapest, HU';
                    break;
                case 'SK':
                    address = 'Bratislava, SK';
                    break;
                case 'CZ':
                    address = 'Praha, CZ';
                    break;
                case 'RO':
                    address = 'Bucuresti, RO';
                    break;
                case 'SI':
                    address = 'Ljubljana, SI';
                    break;
                case 'HR':
                    address = 'Zagreb, HR';
                    break;
            }
        }
        else
        {
            // remove name of recipient from address
            editedAddress = address.split(',');
            address = editedAddress.slice(1).join();
        }

        _ctrcode = ctrcode;
        _isDropOff = isDropOff;
        _initAddress = address;

        if (!isEmpty(senderid)) {
            _senderid = senderid;
        }
        if (!isEmpty(pclshopin)) {
            _pclshopin = pclshopin;
        }
        if (!isEmpty(parcellockin)) {
            _parcellockin = parcellockin;
        }
        if (!isEmpty(codhandler)) {
            _codhandler = codhandler;
        }

        switch (ctrcode.toUpperCase()) {
            case 'HU':
                ajaxUrl = '//online.gls-hungary.com/psmap/psmap_getdata.php';
                break;
            case 'SK':
                ajaxUrl = '//online.gls-slovakia.sk/psmap/psmap_getdata.php';
                break;
            case 'CZ':
                ajaxUrl = '//online.gls-czech.com/psmap/psmap_getdata.php';
                break;
            case 'RO':
                ajaxUrl = '//online.gls-romania.ro/psmap/psmap_getdata.php';
                break;
            case 'SI':
                ajaxUrl = '//online.gls-slovenia.com/psmap/psmap_getdata.php';
                break;
            case 'HR':
                ajaxUrl = '//online.gls-croatia.com/psmap/psmap_getdata.php';
                break;
        }

        // try get lng from url
        var lngFromUrl = getLanguageFromUrl();

        if ($.inArray(lngFromUrl, allowedLangCtrCodes) !== -1) {
            var lngCtrCode = lngFromUrl;
        }
        else {
            var lngCtrCode = ctrcode;
        }

        // load text "Find ParcelShop"
        crossDomainAjax({
            action: 'getLng2',
            country: lngCtrCode.toLowerCase()
        },
            function (data) {
                lngData = getLanguageArray(data);
            }
        );

        // load list of ParcelShops
        crossDomainAjax({
            action: 'getList',
            dropoff: isDropOff
        },
            function (data) {
                for (var i = 0; i < data.length; i++) {
                    if (containsArrayTheParcelShop(psData, data[i]) === false) {
                        psData.push(data[i]);
                    }
                }
            }
        );

        // address initializing and show on the map
        initAddress(address);
    }

    // clear all variables and set the to default values
    var destroy = function () {
        psData = [];
        lngData = [];
        psData_OnMap = [];
        selectedID = '';
        _ctrcode = '';
        _senderid = null;
        _pclshopin = null;
        _parcellockin = null;
        _isDropOff = null;
        _initAddress = null;
        _codhandler = null;
        _baselatlng = {lat : 0, lng : 0};
    }

    // initialization of address after start application
    var initAddress = function (address) {
        /* declare the google geocoder api */
        var geocoder = new google.maps.Geocoder();

        /* add ctrcode to have better match */
        address += "," + _ctrcode.toLowerCase();

        /* add the address and check that it is existing -> callback function */
        geocoder.geocode({ address: address }, addressFound);
    }

    // found address, set marker, set zoom for min. 5 visible PS
    var addressFound = function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            _baselatlng.lat = results[0].geometry.location.lat();
            _baselatlng.lng = results[0].geometry.location.lng();

            updateLeftSide();
        }
    }

    var refreshOnMapDataFilter = function () {
        if (!psData) {
            return;
        }

        var result = [];
        for (var ii = 0; ii < psData.length; ii++) {
            var dist = distance(_baselatlng.lat, _baselatlng.lng, psData[ii]['geolat'], psData[ii]['geolng']);
            if (dist <= 20) {
                result.push(psData[ii]);
            }
        }
    }

    // change of bounds
    var updateLeftSide = function () {
        //refreshOnMapDataFilter();
        psData_OnMap = getVisibleParcelShops(psData, _baselatlng);

        countDistances(psData_OnMap, _baselatlng);
        sortByDistance(psData_OnMap);

        loadPSDataItems();
    }

    // show data of visible ParcelShops on the left side
    var loadPSDataItems = function () {
        if (psData_OnMap) {
            container.html('');

            $.each(psData_OnMap, function(i, item) {
                container.append('<option value="' + item.pclshopid + '">' + item.name + ' (' + item.zipcode + ' ' + item.city + ', ' + item.address + ')</option>');
            });

            self._onSelect.call(false, psData_OnMap[0]);
        }
    }

    // return detail of the parcelshop
    var returnSeleceted = function (id) {
        for (var i = 0; i < psData.length; i++) {
            if (psData[i]['pclshopid'] === id) {
                if (psData[i].openings) {
                    this.glsPSMap_OnSelected_Handler(psData[i]);
                }
                else {
                    crossDomainAjax(
                        {
                            action: 'getOpenings',
                            pclshopid: id
                        },
                        $.proxy(function (data) {
                            psData[i].openings = data;
                            this.glsPSMap_OnSelected_Handler(psData[i]);
                        }, this));
                }
                break;
            }
        }
    }

    // ajax function
    function crossDomainAjax(data, successCallback) {
        var dataToSend = '?ctrcode=' + _ctrcode;
        $.each(data, function (key, value) {
            dataToSend += '&' + key + '=' + value;
        });

        // Extend list query string with optional parameters
        if (data.action == 'getList') {
            if (!isEmpty(_senderid)) {
                dataToSend += '&' + 'senderid' + '=' + _senderid;
            }
            if (!isEmpty(_pclshopin)) {
                dataToSend += '&' + 'pclshopin' + '=' + _pclshopin;
            }
            if (!isEmpty(_parcellockin)) {
                dataToSend += '&' + 'parcellockin' + '=' + _parcellockin;
            }
            if (!isEmpty(_codhandler)) {
                dataToSend += '&' + 'codhandler' + '=' + _codhandler;
            }
        }

        // IE8 & 9 only Cross domain JSON GET request
        if ('XDomainRequest' in window && window.XDomainRequest !== null) {
            var xdr = new XDomainRequest(); // Use Microsoft XDR
            xdr.open('get', ajaxUrl + dataToSend);
            xdr.onload = function () {
                var dom = new ActiveXObject('Microsoft.XMLDOM'),
                JSON = $.parseJSON(xdr.responseText);

                dom.async = false;

                if (JSON == null || typeof (JSON) == 'undefined') {
                    JSON = $.parseJSON(data.firstChild.textContent);
                }

                successCallback(JSON); // internal function
            };

            xdr.onerror = function () {
                _result = false;
            };

            xdr.send();
        }
        // IE7 and lower can't do cross domain
        else if (navigator.userAgent.indexOf('MSIE') != -1 && parseInt(navigator.userAgent.match(/MSIE ([\d.]+)/)[1], 10) < 8) {
            return false;
        }
        // Do normal jQuery AJAX for everything else
        else {
            $.ajax({
                url: ajaxUrl + dataToSend,
                cache: false,
                dataType: 'json',
                type: 'GET',
                async: false, // must be set to false
                success: function (data, success) {
                    successCallback(data);
                }
            });
        }
    }


    function setParam(key, value) {
        switch (key) {
            case 'pclshopin':
                _pclshopin = value;
                return true;
                break;
            case 'parcellockin':
                _parcellockin = value;
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    // load data and show on map via markers
    var loadList = function () {
        crossDomainAjax({
            action: 'getList',
            dropoff: _isDropOff
        },
        function (data) {
            psData = data;
        });

        initAddress(_initAddress);
    }

    return {
        init: init,
        initAddress: initAddress,
        setParam: setParam,
        reloadList: loadList
    };
}();

// used bubble sort for sort array by distance
var sortByDistance = function (listOfPs) {
    var swapped, i, temp;

    do {
        swapped = false;
        for (i = 0; i < listOfPs.length - 1; i++) {
            if (listOfPs[i]['distance'] > listOfPs[i + 1]['distance']) {
                temp = listOfPs[i];
                listOfPs[i] = listOfPs[i + 1];
                listOfPs[i + 1] = temp;
                swapped = true;
            }
        }
    } while (swapped);
}

// count distance for all PS in list
var countDistances = function(listOfPS, _baselatlng)
{
    /* count air distance from address to PS for each PS */
    for (var i = 0; i < listOfPS.length; i++) {
        if (!isEmpty(listOfPS[i]['geolat']) && !isEmpty(listOfPS[i]['geolng'])) {
            var dist = distance(_baselatlng.lat, _baselatlng.lng, listOfPS[i]['geolat'], listOfPS[i]['geolng']);

            listOfPS[i]['distance'] = dist;
        }
    }
    return listOfPS;
}

// return parcelshops which are visible on the map
var getVisibleParcelShops = function (listOfPS, _baselatlng) {
    result = [];

    for (var i = 0; i < listOfPS.length; i++) {
        var dist = distance(_baselatlng.lat, _baselatlng.lng, listOfPS[i]['geolat'], listOfPS[i]['geolng']);

        // is PS visible on the map?
        if (dist <= 20) {
            // is in the array of visible PS?
            if (containsArrayTheParcelShop(result, listOfPS[i]) === false)
            {
                result.push(listOfPS[i]);
            }
        }
    }

    return result;
}

var containsArrayTheParcelShop = function(arrayObjects, testObject)
{
    for (var i = 0; i < arrayObjects.length; i++)
    {
        if (arrayObjects[i].pclshopid === testObject.pclshopid) {
            return true;
            break;
        }
    }

    return false;
}

// return ctrcode of set language from url
var getLanguageFromUrl = function () {
    var getVariables = $(location).attr('search');  // get all variables "?example=value&example2=value2
    var langStart = getVariables.search("lang=");   // search start position of variable lang in the string

    // return substring - country code only
    return getVariables.substring(langStart + 5, 8);
}

//
var getLanguageArray = function (data) {
    var defaultTexts = {
        holiday: "Holiday",
        searchPS: "Search ParcelShop ...",
        dropOff: "Only dispatch!"
    };

    if (isEmpty(data) || isEmpty(data[0]) || data == false) {
        return defaultTexts;
    }
    else {
        $.each(data, function () {
            switch (this['id']) {
                case 'lbl_psmap_search':
                    defaultTexts.searchPS = this['txt'];
                    break;
                case 'txt_holiday':
                    defaultTexts.holiday = this['txt'];
                    break;
                case 'txt_dropOffPoint':
                    defaultTexts.dropOff = this['txt'];
                    break;
            }
        });

        return defaultTexts;
    }
}

var distance = function(lat1, lon1, lat2, lon2) {
      var p = 0.017453292519943295,
          c = Math.cos,
          a = 0.5 - c((lat2 - lat1) * p)/2 +
              c(lat1 * p) * c(lat2 * p) *
              (1 - c((lon2 - lon1) * p))/2;

      return 12742 * Math.asin(Math.sqrt(a));
}

// don't remove!!! is necessary for customers solution
var isEmpty = function (obj) {
    if (typeof obj == 'undefined' || obj === null || obj === '') return true;
    if (typeof obj == 'number' && isNaN(obj)) return true;
    if (obj instanceof Date && isNaN(Number(obj))) return true;
    return false;
}