var ppapi = {};

(function($) {

ppapi = {
	APIURL: 'https://www.postapont.hu/ppapi/',
	pplist: [],
	onSelect: null,
	opts: {},
	skipgroups: {},

	linkZipField: function(zipfield_id){
		$('#'+zipfield_id).bind('change.unbindable', function(e) {
			ppapi.zipcode = $(this).val();
			ppapi.initZip(ppapi.zipcode);
			return false;
		});
		ppapi.zipcode = $('#'+zipfield_id).val();
		ppapi.initZip(ppapi.zipcode);
	},

	apicall: function(method, data, success){
		$.ajax({
			crossDomain: true,
			dataType: 'jsonp',
			data: data,
			url: ppapi.APIURL+method,
			success: success,
			error: function(jqXHR, textStatus, errorThrown){
				alert('error: '+textStatus);
			}
		});
	},

	pointSelect: function(field_id){
		ppapi.points = $('#' + field_id);
	},

	initZip: function(zip){
		if(!zip) return;
		//if(!ppapi.map) return;

		ppapi.apicall('geozip', {
			'zipCode': zip,
			}, function(data, textStatus, jqXHR ){
				if(data.type=='symbolzip'){
					ppapi.zipkrd = new google.maps.LatLng(data.lat, data.lon),
					ppapi.map.setCenter( ppapi.zipkrd );
					ppapi.reloadPP();

				} else if(data.type=='mapzip'){
					geocoder = new google.maps.Geocoder();
					geocoder.geocode( { 'address': zip+', Magyarország', 'region':'HU'}, function(results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							ppapi.zipkrd = results[0].geometry.location;
							ppapi.reloadPP();
						} else {
							alert('Érvénytelen irányítószám!');
						}
					});

				} else alert('Érvénytelen irányítószám!');

			}
		);

	},

	reloadPP: function(fitBounds){
		var skipgroups = Array();
		for(i in ppapi.skipgroups) if(ppapi.skipgroups[i]) skipgroups.push(i);
		ppapi.apicall('listPP', {
			'zipcode': ppapi.zipcode,
			'lat': ppapi.zipkrd.lat(),
			'lng': ppapi.zipkrd.lng(),
			'group': ppapi.opts.group,
			'skipgroups': skipgroups.join(',')
			}, function(data, textStatus, jqXHR ){
				ppapi.pplist = data;
				ppapi.displayPP(fitBounds);
			}
		);
	},

	setOpts: function(opts){
		if(!opts) opts = {};
		ppapi.opts = opts;
		if(!ppapi.opts.group) ppapi.opts.group = '';
	},

	displayPP: function(fitBounds){

		if( fitBounds == undefined ) fitBounds = true;
		var ppselectobj = ppapi.points;
		ppselectobj.empty();

		var lat_min = 90, lat_max = -90, lon_min = 180, lon_max = -180, n = 0;
		if(ppapi.pplist) {
            $.each(ppapi.pplist, function(key, item) {
    			ppselectobj.append($("<option />").val(item.id).text( item.name+' ('+item.zip+' '+item.county+', '+item.address+')' ));
    			if( n==0 || item.distance<30 ) {
    				lat_min = Math.min(lat_min, item.lat);
    				lon_min = Math.min(lon_min, item.lon);
    				lat_max = Math.max(lat_max, item.lat);
    				lon_max = Math.max(lon_max, item.lon);
    				n++;
    			}
    		});
            ppapi.selectPP(ppapi.pplist[0]);
        }

        ppapi.points.change(function(){
			var ppselectobj = $(this);
			if( !ppapi.pplist || ppselectobj.get(0).selectedIndex<0) return;
			ppapi.selectPP(ppapi.pplist[ppselectobj.get(0).selectedIndex]);
		});
	},

	selectPP: function(data){
		if(ppapi.onSelect) ppapi.onSelect(data);
		return false;
	}

};

})(jQuery);
