$('[data-toggle="tooltip"], .with-tooltip').tooltip();

function addToCartTooltip() {
    // xs: 0,
// sm: 576px,
// md: 768px,
// lg: 992px,
// xl: 1200px,
// xxl: 1600px,
    if ($(window).width() < 1600) {
        $('.add-to-cart-btn').tooltip(
            {
                title: 'Kosárba'
            }
        );
    } else {
        $('.add-to-cart-btn').tooltip('disable');
    }
}

$(window).bind("load resize", function () {
    addToCartTooltip();
});

(function ($) {
    'use strict';
    $.fn.tooltipOnOverflow = function () {
        $(this).on("mouseenter", function () {
            if (this.offsetWidth < this.scrollWidth) {
                $(this).tooltip(
                    {
                        title: $(this).text()
                    }
                );
                $(this).tooltip('show');
            } else {
                $(this).tooltip('disable');
            }
        });
    };
})(jQuery);

$('.filter-text').tooltipOnOverflow();

